export const ROUTE_NAMES = {
  ACCOUNT_WATCHLIST: 'account-watchlist',
  ACCOUNT_LOGIN: 'account-login',
  ACCOUNT_NEW_PASSWORD: 'account-password-new',
  ACCOUNT_ACTIVATE: 'account-activate',
  CMS_PAGE: 'pageSlug',
  CITY_PAGE: 'cinema-citySlug',
  ACCOUNT: 'account',
  VOUCHERS: 'vouchers',
  CITIES_PAGE: 'cities',
  PERSONS_PAGE: 'persons',
  PERSON_PAGE: 'person-personSlug',
  CINEMA_PAGE: 'cinema-citySlug-cinemaSlug',
  CINEMA_PROGRAM: 'cinema-citySlug-cinemaSlug-program',
  CINEMA_INFO: 'cinema-citySlug-cinemaSlug-info',
  CINEMA_VOUCHERS: 'cinema-citySlug-cinemaSlug-vouchers',
  CINEMA_SHOP: 'cinema-citySlug-cinemaSlug-shop',
  CINEMA_MOVIE_SHOW_GROUP: 'cinema-citySlug-cinemaSlug-movie-showGroupSlug',
  CINEMA_SHOW_SHOW_SLUG: 'cinema-citySlug-cinemaSlug-show-showSlug',
  CINEMA_FAQ: 'cinema-citySlug-cinemaSlug-faq',
  CINEMAS_PAGE: 'cinemas',
  SEARCH_PAGE: 'search',
  GENRE_PAGE: 'movies-genre-genreSlug',
  GENRES_PAGE: 'movies-genres',
  INDEX: 'index',
  MOVIE_PAGE: 'movie-movieSlug',
  MOVIES_PAGE: 'movies',
  ORDER: 'account-order-orderNumber-securityKey',
} as const
