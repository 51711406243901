import type { Movie, MoviePaginator } from '#gql/default'
export type WatchListMovie = Movie & { hasShows: boolean }

export default function useWatchList() {
  const movies = useState<Movie[]>('watchlist-movies', () => [])
  const paginator = useState<MoviePaginator>('watchlist-paginator')

  let timeoutId: NodeJS.Timeout | null = null

  async function fetch(
    {
      first = 10,
      page = 1,
    }: {
      page?: number
      first?: number
    } = { page: 1, first: 10 }
  ) {
    const response = await GqlFetchWatchList({
      first,
      page,
    })

    if (response?.me?.watchList) {
      setData({
        data: response.me.watchList.data as WatchListMovie[],
        paginatorData: response.me.watchList
          .paginatorInfo as unknown as MoviePaginator,
      })
    }
  }

  async function add({
    movieId,
    page = 1,
  }: {
    movieId: string
    page?: number
  }) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const response = await GqlAddMovieToWatchList({
      movieId,
      first: 10,
      page,
    })

    if (response?.watchListAdd?.watchList) {
      setData({
        data: response.watchListAdd.watchList.data as WatchListMovie[],
        paginatorData: response.watchListAdd.watchList
          .paginatorInfo as unknown as MoviePaginator,
      })
    }
  }

  async function remove({
    movieId,
    page = 1,
    timeout = 0,
  }: {
    movieId: string
    page?: number
    timeout?: number
  }) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const response = await GqlRemoveMovieFromWatchList({
      movieId,
      first: 10,
      page,
    })

    timeoutId = setTimeout(() => {
      if (response?.watchListRemove?.watchList) {
        setData({
          data: response.watchListRemove.watchList.data as WatchListMovie[],
          paginatorData: response.watchListRemove.watchList
            .paginatorInfo as unknown as MoviePaginator,
        })
      }
    }, timeout)
  }

  function setData({
    data,
    paginatorData,
  }: {
    data: WatchListMovie[]
    paginatorData: MoviePaginator
  }) {
    movies.value = data
    paginator.value = paginatorData
  }

  return {
    movies,
    paginator,
    fetch,
    add,
    remove,
  }
}
