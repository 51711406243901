<template>
  <div class="flex flex-col items-center space-y-8 text-center">
    <InlineSvg
      :name="SVG_ICON.CHECK_CIRCLE_OUTLINE"
      class="size-16 fill-green-500"
    />

    <span class="text-lg font-bold" v-text="t('accountRegistered')" />

    <span class="text-lg" v-text="t('emailConfirmationText')" />

    <Button
      :text="t('backToLogin')"
      @click="changeState(USER_AUTH_STATE.LOGIN)"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { changeState } = useAuthState()

defineOptions({
  name: 'AuthRegisterAccountCreated',
})
</script>

<i18n>
de:
  accountRegistered: "Account erfolgreich angelegt!"
  emailConfirmationText: "Bitte prüfe dein E-Mail-Postfach und klicke auf den Link zur Bestätigung deiner Registrierung. Danach kannst du dich im kinoheld Portal anmelden."
  backToLogin: "Zurück zur Anmeldung"
es:
  accountRegistered: "Cuenta creada con éxito!"
  emailConfirmationText: "Por favor, comprueba tu bandeja de entrada y haz clic en el enlace para confirmar tu registro. Después de eso, puedes iniciar sesión en el portal de kinoheld."
  backToLogin: "Volver al inicio de sesión"
</i18n>
