<template>
  <div class="border-neutral flex w-full flex-col space-y-4 border-t py-8">
    <span v-text="t('noAccount')" />

    <Button
      :text="t('registerNow')"
      :theme="BUTTON_THEME.SECONDARY"
      @click="changeState(USER_AUTH_STATE.REGISTER)"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { changeState } = useAuthState()

defineOptions({
  name: 'AuthFooterNoAccount',
})
</script>

<i18n>
de:
  noAccount: "Noch keinen Account?"
  registerNow: "Jetzt kostenlos registrieren"
es:
  noAccount: "¿No tienes una cuenta?"
  registerNow: "Regístrate gratis ahora"
</i18n>
