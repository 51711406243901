import type { AuthTokenPayload } from '#gql/default'

export default function useAccountCookies() {
  const { state } = useUserAuth()

  const { data, error, execute } = useAsyncData(
    'AccountRefreshAccessToken',
    () =>
      GqlAccountRefreshAccessToken({
        refreshToken: state.value.refreshToken ?? '',
      }),
    {
      immediate: false,
    }
  )

  if (state.value.accessToken) {
    useGqlToken(state.value.accessToken)
  }

  async function refreshAuthToken() {
    await execute()

    if (error.value) {
      // TODO: fetchError.value?.cause?.gqlErrors?.[0]?.message
      throw error.value
    }

    if (data.value?.data?.refreshLogin) {
      setAuthTokenCookies(data.value.data.refreshLogin)
    }

    return data.value
  }

  function setAuthTokenCookies(payload: AuthTokenPayload) {
    const today = new Date()
    const accessTokenDate = new Date()
    const refreshTokenDate = new Date()

    accessTokenDate.setDate(today.getDate() + 15)
    refreshTokenDate.setDate(today.getDate() + 30)

    const accessTokenCookie = useCookie(ACCOUNT_ACCESS_TOKEN_COOKIE_NAME, {
      expires: accessTokenDate,
      path: '/',
    })

    const refreshTokenCookie = useCookie(ACCOUNT_REFRESH_TOKEN_COOKIE_NAME, {
      expires: refreshTokenDate,
      path: '/',
    })

    state.value.accessToken = payload.accessToken
    state.value.refreshToken = payload.refreshToken

    accessTokenCookie.value = payload.accessToken
    refreshTokenCookie.value = payload.refreshToken

    useGqlToken(payload.accessToken)
  }

  return {
    refreshAuthToken,
    setAuthTokenCookies,
  }
}
