import { URL_FILTER } from '@base/constants/url-filter'
import { IMAGE_MODIFIER } from '@base/constants/image'
import type { ImageModifier } from '#gql/default'
interface MovieListIds {
  HIGHLIGHTS: string
  NOW_PLAYING: string
  COMING_SOON: string
}

const movieListIds: MovieListIds = {
  HIGHLIGHTS: '23',
  NOW_PLAYING: '35',
  COMING_SOON: '85',
}

const filtersForGenericMoviesPage: UrlFilter[] = [
  URL_FILTER.SEARCH,
  URL_FILTER.CITY,
  URL_FILTER.PERIODS,
  URL_FILTER.GENRES,
  URL_FILTER.CONTENT_RATINGS,
]

const filtersForCinemaProgramPage: UrlFilter[] = [
  URL_FILTER.SEARCH,
  URL_FILTER.DATES,
  URL_FILTER.LANGUAGE,
  URL_FILTER.TECHNOLOGY,
  URL_FILTER.SHOW_GROUPS,
  URL_FILTER.CONTENT_RATINGS,
  URL_FILTER.GENRES,
  URL_FILTER.EVENT,
  URL_FILTER.MISCELLANEOUS,
]

const fullWidthHeroBlocks = true
const cinemaHeroImageModifiers: ImageModifier[] = [
  { key: IMAGE_MODIFIER.BLUR, value: '65' },
  { key: IMAGE_MODIFIER.QUALITY, value: '75' },
  { key: IMAGE_MODIFIER.FIT, value: 'crop' },
  { key: IMAGE_MODIFIER.HEIGHT, value: '600' },
]
const movieHeroImageModifiers: ImageModifier[] = []

export default defineAppConfig({
  movieListIds,
  filtersForGenericMoviesPage,
  filtersForCinemaProgramPage,
  fullWidthHeroBlocks,
  cinemaHeroImageModifiers,
  movieHeroImageModifiers,
  roktAccountId: '3238448877769654272',
})
