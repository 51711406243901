<template>
  <div class="flex w-full justify-center md:justify-start">
    <button
      class="w-1/2 rounded-l-full py-3 pl-6 pr-5 md:w-auto"
      :class="{
        'bg-primary text-on-primary hover:bg-primary-dark-1':
          activeElement === 'allMovies',
        'bg-light dark:bg-dark hover:bg-neutral-dark-2 border-dark dark:border-light text-dark hover:text-light dark:text-light border-y border-l':
          activeElement != 'allMovies',
      }"
      @click="emit('update:activeElement', 'allMovies')"
    >
      {{ t('allMovies') }}
    </button>
    <button
      class="w-1/2 rounded-r-full py-3 pl-5 pr-6 md:w-auto"
      :class="
        activeElement === 'currentMovies'
          ? 'bg-primary text-on-primary hover:bg-primary-dark-1'
          : 'bg-light dark:bg-dark hover:bg-neutral-dark-2 border-dark dark:border-light text-dark hover:text-light dark:text-light border-y border-r'
      "
      @click="emit('update:activeElement', 'currentMovies')"
    >
      {{ t('currentMovies') }}
    </button>
  </div>
</template>

<script setup lang="ts">
interface Props {
  activeElement: 'allMovies' | 'currentMovies'
}

defineProps<Props>()

const emit = defineEmits(['update:activeElement'])

const { t } = useI18n()

defineOptions({
  name: 'AccountWatchListMovieButtons',
})
</script>

<i18n>
de:
  allMovies: "Alle Filme"
  currentMovies: "Aktuell im Kino"
es:
  allMovies: "Todas las películas"
  currentMovies: "Actualmente en cines"
</i18n>
