<template>
  <div>
    <div v-if="errorMessage" class="relative h-12">
      <AuthErrorMessage :error="errorMessage" />
    </div>

    <FormKit
      v-model="formData"
      type="form"
      :actions="false"
      novalidate
      :classes="{ form: 'flex flex-col space-y-4' }"
      @submit="submit"
    >
      <FormKitMessages class="text-system-error mb-4" />

      <span class="text-start" v-text="t('enterEmailText')" />

      <FormKit
        name="email"
        type="email"
        :label="t('emailAddress')"
        :floating-label="true"
        validation="required|email"
        :validation-messages="{
          required: t('requiredEmail'),
        }"
      />

      <Button
        :text="t('resetPassword')"
        :type="SUBMIT"
        :theme="PRIMARY"
        class="mt-4"
      />
    </FormKit>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { changeState } = useAuthState()

const { SUBMIT } = BUTTON_TYPE
const { PRIMARY } = BUTTON_THEME
const { RESET_PASSWORD_CONFIRMATION } = USER_AUTH_STATE

const formData = ref()
const errorMessage = ref()

async function submit() {
  const { data, error } = await useAsyncGql('AccountTriggerPasswordRecovery', {
    email: formData.value?.email,
  })

  if (data.value?.accountTriggerPasswordRecovery) {
    changeState(RESET_PASSWORD_CONFIRMATION)
  }

  if (error.value) {
    errorMessage.value =
      error.value?.cause?.gqlErrors?.[0]?.extensions?.message ??
      error.value?.cause?.gqlErrors?.[0]?.message
  }
}

defineOptions({
  name: 'AuthResetPassword',
})
</script>

<i18n>
de:
  emailAddress: "E-Mail-Adresse"
  enterEmailText: "Bitte gib deine E-Mail-Adresse an, damit wir dir einen Link zum Zurücksetzen deines Passworts schicken können. Dieser ist 1 Stunde gültig."
  resetPassword: "Passwort zurücksetzen"
  requiredEmail: 'Bitte E-Mail-Adresse eingeben.'
es:
  emailAddress: "Correo electrónico"
  enterEmailText: "Por favor, introduce tu dirección de correo electrónico para que podamos enviarte un enlace para restablecer tu contraseña. Este enlace es válido por 1 hora."
  resetPassword: "Restablecer contraseña"
  requiredEmail: 'Por favor, introduce una dirección de correo electrónico.'
</i18n>
