<template>
  <MovieLink
    :movie
    :link-params
    :disabled="hideLink"
    class="flex flex-col text-center text-sm font-light"
    :class="{ 'hover:underline': !hideLink }"
  >
    <slot />
    <MoviePosterImage
      :key="movie.id"
      data-image
      :image-object
      :image-config
      :rounded="ROUNDED_TYPE.LG"
    />

    <div class="break-words pt-3 leading-tight" v-text="movie.title" />
  </MovieLink>
</template>

<script setup lang="ts">
import type { Movie, DynamicMovie, LinkParam } from '#gql/default'

interface Props {
  movie: Movie | DynamicMovie
  hideLink?: boolean
  renderAboveTheFold?: boolean
  linkParams: LinkParam[]
}

const props = withDefaults(defineProps<Props>(), {
  linkParams: () => [],
  hideLink: false,
  renderAboveTheFold: false,
})
const imageObject = getImage(props.movie, 'thumbnailImage', props.movie.title)

const config = useAppConfig()
const imageConfig = computed(() => ({
  ...config.images.movie.grid,
  loading: props.renderAboveTheFold ? IMAGE_LOADING.EAGER : IMAGE_LOADING.LAZY,
}))

defineOptions({
  name: 'MovieCard',
})
</script>
