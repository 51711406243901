<template>
  <ModalContent
    :open="true"
    :title="title"
    :classes="{
      outer: 'flex flex-col self-center w-full h-auto md:w-[670px]',
      header:
        'h-16 pl-8 pr-4 text-lg grid grid-cols-[1fr,auto] items-center border-b border-neutral',
      modal:
        'items-center rounded-none md:rounded-lg bg-light text-dark dark:bg-neutral-dark-2 dark:text-light drop-shadow-2xl h-dvh  md:h-auto',
      content:
        'max-h-full px-safe-offset-5 sm:px-safe-offset-8 py-4 pt-5 sm:pt-8 justify-center items-center flex flex-col',
      footer:
        'w-full md:w-auto h-auto px-5 md:px-8 flex items-center justify-between absolute md:relative bottom-0',
    }"
    @close="emit('close')"
  >
    <template #default>
      <AuthLogin v-if="state === LOGIN" @close="emit('close')" />

      <AuthRegister v-else-if="state === REGISTER" />

      <AuthRegisterAccountCreated
        v-else-if="state === REGISTER_ACCOUNT_CREATED"
      />

      <AuthResetPassword v-else-if="state === RESET_PASSWORD" />

      <AuthResetPasswordConfirmation
        v-else-if="state === RESET_PASSWORD_CONFIRMATION"
      />
    </template>

    <template #footer>
      <AuthFooterNoAccount v-if="showNoAccountFooter" />

      <AuthFooterCheckSpam v-else-if="showCheckSpamFooter" />
    </template>
  </ModalContent>
</template>

<script setup lang="ts">
interface Props {
  type: UserAuthStateTypes
}

const { type } = defineProps<Props>()

const emit = defineEmits(['close'])

const { state, title, showNoAccountFooter, showCheckSpamFooter, changeState } =
  useAuthState()
changeState(type)

const {
  LOGIN,
  REGISTER,
  REGISTER_ACCOUNT_CREATED,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRMATION,
} = USER_AUTH_STATE

defineOptions({
  name: 'AuthModal',
})
</script>

<i18n>
de:
  login: "Anmelden"
  createAccount: "Account erstellen"
  resetPassword: "Password zurücksetzen"
es:
  login: "Iniciar sesión"
  createAccount: "Crear cuenta"
  resetPassword: "Restablecer contraseña"
</i18n>
